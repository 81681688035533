<template>
  <table  class="table-auto dark:text-stone-100 shadow-own">
    <thead class="border-black dark:border-white border-2 ">
    <tr>
      <th class="p-1 md:p-2 break-words whitespace-normal text-left">Dag</th>
      <th class="p-1 md:p-2 break-words whitespace-normal text-left">Datum</th>
      <th class="p-1 md:p-2 break-words whitespace-normal text-left">Vanaf</th>
      <th class="p-1 md:p-2 break-words whitespace-normal text-left">Tot</th>
      <th class="p-1 md:p-2 break-words whitespace-normal text-left">Fooi</th>
      <th class="md:table-cell hidden p-1 md:p-2 break-words whitespace-normal text-left">Loon</th>
      <th class="p-1 md:p-2 text-left">
        <button @click.prevent="createWorkingTime" class="rounded h-7 w-7 p-2 bg-green-500 shadow-own hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-900 duration-200">
          <img class="h-full w-full" src="../../../assets/plus-white.png" alt="Plus"/>
        </button>
      </th>
    </tr>
    </thead>
    <tbody class="border-black dark:border-white border-2">
    <tr v-for="workingTime in workingTimes.list" :key="workingTime.id">
      <td :class="{
          'underline': workingTime.holiday,
          'font-bold': isToday(workingTime.dateObject),
          'italic': isFuture(workingTime.dateObject) && !isToday(workingTime.dateObject)
        }" class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ workingTime.dayName }}</td>
      <td :class="{
          'underline': workingTime.holiday,
          'font-bold': isToday(workingTime.dateObject),
          'italic': isFuture(workingTime.dateObject) && !isToday(workingTime.dateObject)
        }" class="p-1  md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ workingTime.date }}</td>
      <td :class="{
          'underline': workingTime.holiday,
          'font-bold': isToday(workingTime.dateObject),
          'italic': isFuture(workingTime.dateObject) && !isToday(workingTime.dateObject)
        }" class="p-1  md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ workingTime.startTime }}</td>
      <td :class="{
          'underline': workingTime.holiday,
          'font-bold': isToday(workingTime.dateObject),
          'italic': isFuture(workingTime.dateObject) && !isToday(workingTime.dateObject)
        }" class="p-1  md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ workingTime.endTime }}</td>
      <td :class="{
          'underline': workingTime.holiday,
          'font-bold': isToday(workingTime.dateObject),
          'italic': isFuture(workingTime.dateObject) && !isToday(workingTime.dateObject)
        }" class="p-1  md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">€ {{ workingTime.tipFormatted }}</td>
      <td :class="{
          'underline': workingTime.holiday,
          'font-bold': isToday(workingTime.dateObject),
          'italic': isFuture(workingTime.dateObject) && !isToday(workingTime.dateObject)
        }" class="p-1  md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal md:table-cell hidden">€ {{ workingTime.wage}}</td>
      <td :class="{
          'underline': workingTime.holiday,
          'font-bold': isToday(workingTime.dateObject),
          'italic': isFuture(workingTime.dateObject) && !isToday(workingTime.dateObject)
        }" class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">
        <div class="flex gap-1">
          <button @click.prevent="getWorkingTime(workingTime.id)" class="rounded h-7 w-7 p-2 bg-black dark:bg-stone-100 shadow-own hover:bg-stone-700 dark:hover:bg-stone-300 duration-200">
            <img class="h-full w-full" :src="isDark ? require(`../../../assets/info-black.png`) : require('../../../assets/info-white.png')" alt="Info"/>
          </button>
          <button @click.prevent="editWorkingTime(workingTime.id)" class="rounded h-7 w-7 p-2 bg-yellow-500 shadow-own hover:bg-yellow-700 dark:bg-yellow-700 dark:hover:bg-yellow-900 duration-200">
            <img  class="h-full w-full" src="../../../assets/edit-white.png" alt="Bin"/>
          </button>
          <button @click.prevent="deleteWorkingTime(workingTime.id)" class="rounded h-7 w-7 p-2 bg-red-500 shadow-own hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-900 duration-200">
            <img class="h-full w-full" src="../../../assets/bin.png" alt="Bin"/>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <WorkExpensesComponent></WorkExpensesComponent>

  <div class="my-4 shadow-own">
    <div class="table-auto dark:text-stone-100 shadow-own">
      <div class="border-black dark:border-white border-2">
        <!-- Existing totals -->
        <div class="p-2 md:p-4 break-words whitespace-normal text-left">
          <strong>{{canChangeSymbol}} Totale Uren en Minuten gewerkt   :   </strong> {{ totalHoursAndMinutesWorked }}
        </div>
        <div class="p-2 md:p-4 break-words whitespace-normal text-left">
          <strong>{{canChangeSymbol}} Totale Fooi   :    </strong>€ {{ totalTips }}
        </div>
        <div class="p-2 md:p-4 break-words whitespace-normal text-left">
          <strong>{{canChangeSymbol}} Totale Loon (BRUTO)   :    </strong>€ {{ totalWage }}
        </div>
        <div v-if="workExpenses.list.length > 0" class="p-2 md:p-4 break-words whitespace-normal text-left">
          <strong>{{canChangeSymbol}} Totale Werk Uitgaven    :    </strong>€ {{ totalWorkExpenses }}
        </div>
        <div class="p-2 md:p-4 break-words whitespace-normal text-left">
          <strong>{{canChangeSymbol}} Totale Loon + Totale Fooi    :    </strong>€ {{ totalWagePlusTotalTips }}
        </div>
        <div v-if="workExpenses.list.length > 0" class="p-2 md:p-4 break-words whitespace-normal text-left">
          <strong>{{canChangeSymbol}} Totale Loon + Totale Fooi + Totale Werk Uitgave    :    </strong>€ {{ totaleWagePlusTotalTipsPlusWorkExpenses }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive, ref, watch} from "vue";
import {useDark} from "@vueuse/core";
import {useRoute, useRouter} from "vue-router";
import useEventsBus from "../../../../eventBus";
import {useStore} from "vuex";
import WorkExpensesComponent from "@/components/workExpenses/WorkExpenses.vue";
const { workExpenseService,workingTimeService, sessionSBService, userService } = inject('data');
const isDark = useDark()
const router = useRouter();
const route = useRoute()
const user = reactive({
  id: '',
  birthDate: '',
  age: '',
  permanentContract: '',
  hourlyWage: ''
});
const canChangeSymbol = ref('')
const { bus } = useEventsBus();
const totalTips = ref('')
const totalHoursAndMinutesWorked = ref()
const totalWage = ref()
const totalWagePlusTotalTips = ref()
const totaleWagePlusTotalTipsPlusWorkExpenses = ref()
const workingTimes = reactive({list: []})
const store = useStore();
const totalHours = ref()
const selectedMonth = ref(route.params.monthId)
const selectedYear = ref(route.params.yearId)
const timeZone = 'Europe/Amsterdam';
const totalWorkExpenses = ref(0);
const { emit } = useEventsBus();
const workExpenses = reactive({ list: [] });

const getAllWorkExpenses = async () => {
  try {
    const { data } = await workExpenseService.getAllWorkExpensesBy(selectedMonth.value, user.id);
    workExpenses.list = data.map(e => ({
      ...e,
      priceFormatted: e.price.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
    }));
    updateWorkExpenses()
    console.log(workExpenses.list)
  } catch (e) {
    console.error(e);
  }
};

const token = sessionSBService.getTokenFromBrowserStorage();

const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });
    await getAllWorkingTimes()
    await getAllWorkExpenses()
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')

      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }
};

const editWorkingTime = async (workingTimeId) => {
  await router.push(`/werktijd/wijzigen/${workingTimeId}`)

}

const getWorkingTime = async (workingTimeId) => {
  await router.push(`/werktijd/${workingTimeId}`)
}

const deleteWorkingTime = async (workingTimeId) => {
  try {
    const {message} = await workingTimeService.removeWorkingTime(workingTimeId)
    store.commit('setSuccessMessage', message)
    await getAllWorkingTimes()
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}



const createWorkingTime = async () => {
  console.log(selectedYear.value)
  await router.push(`/werktijd/aanmaken/${selectedYear.value}/${selectedMonth.value}`)
}

const isToday = (date) => {
  const today = new Date();
  return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
};

const isFuture = (date) => {
  const today = new Date();
  return date > today;
};

const getMonthIndexFromDutchName = (monthName) => {
  const monthNamesDutch = [
    'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni',
    'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'
  ];

  return monthNamesDutch.findIndex(name => name.toLowerCase() === monthName.toLowerCase());
};

const getDayNameInDutchAbbreviation = (year, monthIndex, day) => {
  const date = new Date(Date.UTC(year, monthIndex, day));
  const formatter = new Intl.DateTimeFormat('nl-NL', { weekday: 'long', timeZone });
  const abbreviation = formatter.format(date).slice(0, 3);
  return abbreviation.charAt(0).toUpperCase() + abbreviation.slice(1);
};

const getAllWorkingTimes = async () => {
  try {
    const { data } = await workingTimeService.getAllWorkingTimesBy(selectedMonth.value, user.id);
    workingTimes.list = data.map(w => ({
      ...w,
      dayName: getDayNameInDutchAbbreviation(w.month.year.year, getMonthIndexFromDutchName(w.month.month), w.day),
      date: `${w.day} - ${getMonthIndexFromDutchName(w.month.month) + 1}`, // Assuming getMonthIndexFromDutchName function is defined
      tipFormatted: w.tip.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      wage: calculateWage(w.startTime, w.endTime, user.hourlyWage, w.holiday),
      dateObject: new Date(Date.UTC(w.month.year.year, getMonthIndexFromDutchName(w.month.month), w.day))
    }));
  } catch (e) {
    console.error(e);
  }
};



const calculateWage = (startTime, endTime, hourlyWage, holiday) => {
  const [startHour, startMinute] = startTime.split(':').map(Number);
  let endHour, endMinute;

  if (endTime.toLowerCase() === 'sluit') {
    canChangeSymbol.value = '~'
    // Calculate end time based on month and day of the week
    const date = new Date();
    const month = date.getMonth(); // Month index is zero-based
    const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, etc.

    // Define end time based on month and day of the week
    if ((month >= 3 && month <= 9) || (month === 10 && dayOfWeek === 0) || (month === 10 && dayOfWeek === 6)) {
      // April to October or November (if it falls on a weekend)
      endHour = 22;
      endMinute = dayOfWeek === 0 ? 30 : 0; // Sunday: 22:30, otherwise 22:00
    } else {
      // November to March or November (if it falls on a weekday)
      endHour = 21;
      endMinute = dayOfWeek === 0 ? 30 : 0; // Sunday: 21:30, otherwise 21:00
    }
  } else {
    // If endTime is not "sluit", parse it as a regular time
    [endHour, endMinute] = endTime.split(':').map(Number);
  }

  let hoursWorked;
  if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
    // If endTime is earlier than startTime, it's assumed to be on the next day
    hoursWorked = (24 - startHour + endHour) + ((endMinute - startMinute) / 60);
  } else {
    hoursWorked = (endHour - startHour) + ((endMinute - startMinute) / 60);
  }

  calculateAge()
  let wage = hoursWorked * hourlyWage

  if (holiday) {
    wage = wage * 1.25
  }

  return wage.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) // Calculating wage and fixing to 2 decimal places
};



const calculateTotalHoursAndMinutes = () => {
  let totalMinutes = 0;

  workingTimes.list.forEach(workingTime => {
    const [startHour, startMinute] = workingTime.startTime.split(':').map(Number);
    let endHour, endMinute;

    if (workingTime.endTime.toLowerCase() === 'sluit') {
      // Calculate end time based on month and day of the week
      const date = new Date(workingTime.dateObject);
      const month = date.getMonth(); // Month index is zero-based
      const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, etc.

      // Define end time based on month and day of the week
      if ((month >= 3 && month <= 9) || (month === 10 && dayOfWeek === 0) || (month === 10 && dayOfWeek === 6)) {
        // April to October or November (if it falls on a weekend)
        endHour = 22;
        endMinute = dayOfWeek === 0 ? 30 : 0; // Sunday: 22:30, otherwise 22:00
      } else {
        // November to March or November (if it falls on a weekday)
        endHour = 21;
        endMinute = dayOfWeek === 0 ? 30 : 0; // Sunday: 21:30, otherwise 21:00
      }
    } else {
      // If endTime is not "sluit", parse it as a regular time
      [endHour, endMinute] = workingTime.endTime.split(':').map(Number);
    }

    let minutesWorked;
    if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
      // If endTime is earlier than startTime, it's assumed to be on the next day
      minutesWorked = (24 * 60 - (startHour * 60 + startMinute)) + (endHour * 60 + endMinute);
    } else {
      minutesWorked = (endHour * 60 + endMinute) - (startHour * 60 + startMinute);
    }

    totalMinutes += minutesWorked;
  });

  const hours = Math.floor(totalMinutes / 60);

  totalHours.value = Math.round(hours * 100) / 100;

  const minutes = totalMinutes % 60;

  return `${hours} uren en ${minutes} minuten`;
};


// Function to calculate total tips
const calculateTotalTips = () => {
  let totalTips = 0;

  workingTimes.list.forEach(workingTime => {
    totalTips += workingTime.tip;
  });

  return totalTips.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

// Function to calculate total wage
const calculateTotalWage = () => {
  let totalWage = 0;

  workingTimes.list.forEach(workingTime => {
    totalWage += parseFloat(workingTime.wage.replace(',', '.'));
  });


  if (!user.permanentContract) {
    const holidayHours = totalHours.value * 1.5
    const holidayMoney = totalWage / 12.4
    totalWage = totalWage + holidayHours + holidayMoney
  }

  if (user.age >= 21) {
    const pension = 24.50
    const min = totalWage / pension

    const total = totalWage - min
    return total.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) // Calculating wage and fixing to 2 decimal places
  }

  return totalWage.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

// Function to calculate total wage plus tips
const calculateTotalWagePlusTips = () => {
  const cleanValue = (value) => {
    return parseFloat(value.replace('€', '').replace('.', '').replace(',', '.').trim());
  };

  const wage = cleanValue(totalWage.value);
  const tips = cleanValue(totalTips.value);
  const total = wage + tips;

  return total.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const calculateTotalWagePlusTipsPlusWorkExpenses = () => {
  const cleanValue = (value) => {
    return parseFloat(value.replace('€', '').replace('.', '').replace(',', '.').trim());
  };

  const wage = cleanValue(totalWage.value);
  const tips = cleanValue(totalTips.value);
  const totalExpenses = cleanValue(totalWorkExpenses.value)
  const total = wage + tips + totalExpenses;

  return total.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};


watch(() => bus.value.get('updateSelectMonth'), async (newValue) => {
  selectedMonth.value = newValue
  await getAllWorkingTimes()
  await getAllWorkExpenses()

});



watch(() => workingTimes.list, () => {
  updateTotals();
})

const calculateAge = () => {
  if (user.birthDate == null) return 0; // Return 0 if birthDate is empty or undefined

  const birth = new Date(user.birthDate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  const m = today.getMonth() - birth.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  user.age = age
}

watch(selectedMonth, async(newValue) => {
  selectedMonth.value = newValue
  await getAllWorkingTimes()
  await getAllWorkExpenses()

});

watch(
    () => bus.value.get('updateSelectMonth'),
    async (newMonthId) => {
      selectedMonth.value = newMonthId[0];

    }
);

watch(
    () => bus.value.get('updateSelectYear'),
    async (newYearId) => {
      selectedYear.value = newYearId[0];
    }
);
const calculateTotalWorkExpenses = () => {
  let total = 0;

  // Log the workExpenses.list to debug its structure

  workExpenses.list.forEach(expense => {
    // Ensure the price is a number before adding it to the total
    const expensePrice = parseFloat(expense.price);
      total += expensePrice;

  });


  // Return the formatted total
  return total.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};


// Update `updateTotals` function to include work expenses
const updateTotals = () => {
  totalHoursAndMinutesWorked.value = calculateTotalHoursAndMinutes();
  totalTips.value = calculateTotalTips();
  totalWage.value = calculateTotalWage();
  totalWagePlusTotalTips.value = calculateTotalWagePlusTips();
};

const updateWorkExpenses = () => {
  totalWorkExpenses.value = calculateTotalWorkExpenses();
  totaleWagePlusTotalTipsPlusWorkExpenses.value = calculateTotalWagePlusTipsPlusWorkExpenses()
}

watch(() => bus.value.get('deleteWorkExpense'), () => {
  getAllWorkingTimes()
  getAllWorkExpenses()

})

onMounted(() => {
  getUserByToken()
})
</script>