<template>
  <component  :is="loggedIn ? HomeLoggedIn: HomeNotLoggedIn"/>
</template>
<script setup>
import {defineAsyncComponent, inject, onMounted, ref, watch} from "vue";
import useEventsBus from "../../../eventBus";

const { sessionSBService } = inject('data');
const loggedIn = ref(false);
const HomeLoggedIn = defineAsyncComponent(() => import('@/components/home/HomeLoggedIn.vue'));
const HomeNotLoggedIn = defineAsyncComponent(() => import('@/components/home/HomeNotLoggedIn.vue'));
const {bus} = useEventsBus();

const isLoggedIn = () => {
  if (sessionSBService.isAuthenticated()) loggedIn.value = true
  if (!sessionSBService.isAuthenticated()) loggedIn.value = false
}

watch(() => bus.value.get('handleLogin'), () => {
  loggedIn.value = true

})

watch(() => bus.value.get('handleLogout'), () => {
  loggedIn.value = false
})

onMounted(() => {
  isLoggedIn()
})
</script>



