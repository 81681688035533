<template>
  <!-- Work Expenses -->
  <div v-if="workExpenses.list.length > 0" >
    <h2 class="dark:text-stone-100 text-xl font-semibold mb-2">Werkuitgaven</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div
          v-for="workExpense in workExpenses.list"
          :key="workExpense.id"
          class="p-4 bg-white dark:bg-stone-900 rounded-lg shadow-own"
      >
        <h3 class="text-lg font-bold dark:text-white">{{ workExpense.item }}</h3>
        <p class="text-sm dark:text-gray-300">
          <strong>Datum:</strong> {{ workExpense.day }} {{ month.month}}
        </p>
        <p class="text-sm dark:text-gray-300">
          <strong>Waar gekocht:</strong> {{ workExpense.location }}
        </p>
        <p class="text-sm dark:text-gray-300">
          <strong>Prijs:</strong> € {{ workExpense.priceFormatted }}
        </p>
        <div class="mt-2 flex gap-2">
          <button
              @click.prevent="editWorkExpense(workExpense.id)"
              class="p-2 bg-yellow-500 text-white rounded shadow hover:bg-yellow-700 dark:bg-yellow-600 dark:hover:bg-yellow-800 duration-200"
          >
            Bewerk
          </button>
          <button
              @click.prevent="deleteWorkExpense(workExpense.id)"
              class="p-2 bg-red-500 text-white rounded shadow hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 duration-200"
          >
            Verwijder
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive, ref, watch} from "vue";
import useEventsBus from "../../../eventBus";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
const store = useStore();
const user = reactive({
  id: '',
  birthDate: '',
  age: '',
  permanentContract: '',
  hourlyWage: ''
});
const month = reactive({
  id: '',
  month: ''
})
const { bus } = useEventsBus();

const router = useRouter();
const route = useRoute()
const { workExpenseService, sessionSBService, userService, monthService } = inject('data');
const token = sessionSBService.getTokenFromBrowserStorage();

const selectedMonth = ref(route.params.monthId)
const { emit } = useEventsBus();

const workExpenses = reactive({ list: [] });

const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });
    await getAllWorkExpenses()
    const monthData = await monthService.getMonthById(selectedMonth.value)
    Object.assign(month, {...monthData.data})
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')

      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }
};

watch(selectedMonth, async(newValue) => {
  selectedMonth.value = newValue
  await getAllWorkExpenses()

});

watch(
    () => bus.value.get('updateSelectMonth'),
    async (newMonthId) => {
      selectedMonth.value = newMonthId[0];

    }
);


const getAllWorkExpenses = async () => {
  try {
    const { data } = await workExpenseService.getAllWorkExpensesBy(selectedMonth.value, user.id);
    workExpenses.list = data.map(e => ({
      ...e,
      priceFormatted: e.price.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
    }));
  } catch (e) {
    console.error(e);
  }
};


const editWorkExpense = async (workExpenseId) => {
  await router.push(`/werkuitgaven/wijzigen/${workExpenseId}`)

}


const deleteWorkExpense = async (workExpenseId) => {
  try {
    const {message} = await workExpenseService.removeWorkExpense(workExpenseId)
    store.commit('setSuccessMessage', message)
    emit('deleteWorkExpense')

    await getAllWorkExpenses()
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}

onMounted(() => {
  getUserByToken()
})
</script>
