<template>
  <component  :is="loggedIn ? NavBarLoggedIn: NavBarNotLoggedIn" @logout="handleLogout"/>

</template>

<script setup>


import {inject, ref, onMounted, defineAsyncComponent, watch} from 'vue';
import useEventsBus from "../../../eventBus";
const { sessionSBService } = inject('data');
const loggedIn = ref(false);
const NavBarLoggedIn = defineAsyncComponent(() => import('@/components/navbar/NavBarLoggedIn.vue'));
const NavBarNotLoggedIn = defineAsyncComponent(() => import('@/components/navbar/NavBarNotLoggedIn.vue'));
const {bus} = useEventsBus();


const isLoggedIn = () => {
  if (sessionSBService.isAuthenticated()) loggedIn.value = true
  if (!sessionSBService.isAuthenticated()) loggedIn.value = false

}
const handleLogout = () => {
  loggedIn.value = false;

};

watch(() => bus.value.get('handleLogin'), () => {
  loggedIn.value = true

})

watch(() => bus.value.get('handleLogout'), () => {
  loggedIn.value = false

})



onMounted(() => {
  isLoggedIn()
})
</script>

