<template>
  <div class="dark:bg-stone-800 p-7 flex justify-center">
    <div class="w-8/12">
      <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
      <form class="flex flex-col my-4 gap-2">
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': workingTime.day }" for="day">Dag</label>
          <input v-model="workingTime.day" @input="validateDay" :class="dayClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="day"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ dayError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': workingTime.startTime }" for="startTime">Vanaf</label>
          <input v-model="workingTime.startTime" @input="validateStartTime" :class="startTimeClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="startTime"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ startTimeError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': workingTime.endTime }" for="endTime">Tot (Sluit toegestaan)</label>
          <input v-model="workingTime.endTime" @input="validateEndTime" :class="endTimeClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="endTime"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ endTimeError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': workingTime.tip }" for="tip">Fooi</label>
          <input v-model="workingTime.tip" @input="validateTip" :class="tipClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="tip"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ tipError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label class="p-2 text-xs opacity-50 dark:text-stone-100"
                 :class="{ 'active-label': workingTime.holiday }"
                 for="holiday">Feestdag</label>
          <div class="relative ">
            <input type="checkbox"
                   v-model="workingTime.holiday"
                   class="hidden"
                   id="holiday" />
            <div class="mx-2 select-none toggle-bg  h-6 w-12 rounded-full shadow-inner"  @click="toggleHoliday"
                 :class="workingTime.holiday ? 'bg-orange-500 dark:bg-orange-700' : 'bg-gray-200 dark:bg-stone-700'" ></div>
            <div class="mx-2 select-none cursor-pointer toggle-dot absolute top-1 left-1 bg-white dark:bg-stone-100 w-4 h-4 rounded-full transition transform"
                 :class="{ 'translate-x-6': workingTime.holiday }" @click="toggleHoliday"></div>
          </div>
        </div>
        <div class=" w-full ">
          <button v-if="!isLoading" class="w-full" type="submit" :disabled="dayValid === false || startTimeValid === false || endTimeValid === false || tipValid === false || !workingTime.day || !workingTime.startTime || !workingTime.endTime" @click.prevent="createWorkingTime" :class="buttonClass">Aanmaken</button>
          <Spinner v-if="isLoading"></Spinner>
        </div>
      </form>
    </div>
  </div>

</template>

<script setup>
import {computed, inject, onMounted, reactive, ref} from "vue";
import router from "@/router";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import useEventsBus from "../../../eventBus";
import Spinner from "@/components/Spinner.vue";
const store = useStore();
const isLoading = ref(false)
const workingTime = reactive({
  id:'',
  day: '',
  startTime: '',
  endTime: '',
  tip: '',
  holiday: '',
  month: {
  }
})
const user = reactive({
  id: ''
});

const toggleHoliday = () => {
  workingTime.holiday = !workingTime.holiday;
};
const route = useRoute()
const {workingTimeService, userService, sessionSBService} = inject('data')
const dayValid = ref(null)
const startTimeValid = ref(null)
const endTimeValid = ref(null)
const tipValid = ref(null)
const dayError = ref('')
const startTimeError = ref('')
const endTimeError = ref('')
const tipError = ref('')
const createSuccessful = ref(null)
const { emit } = useEventsBus();
const token = sessionSBService.getTokenFromBrowserStorage();
const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });

  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')
      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }
};

const back = () => {
  router.push(`/werktijden/${route.params.yearId}/${route.params.monthId}`)
}





const createWorkingTime = async () => {
  try {
    isLoading.value = true
    if (workingTime.tip === '') {
      workingTime.tip = '0'
    }
    workingTime.tip = parseFloat(workingTime.tip.replace(",","."))
    if (workingTime.endTime.toLowerCase() === "sluit") {
      workingTime.endTime = "Sluit";
    }
    const {message} = await workingTimeService.createWorkingTime(workingTime, route.params.monthId, user.id)
    store.commit('setSuccessMessage', message)
    await router.push(`/werktijden/${route.params.yearId}/${route.params.monthId}`)

  } catch (e) {
    store.commit('setErrorMessage', e.message)

  } finally {
    isLoading.value = false
  }
}

const validateDay = () => {
  const day = parseInt(workingTime.day); // Assuming dayInput is the input element for the day
  if (createSuccessful.value === false) {
    createSuccessful.value = null
    dayValid.value = null
    startTimeValid.value = null
    endTimeValid.value = null
    tipValid.value = null
  } else if (isNaN(day) || day < 1 || day > 31) {
    dayError.value = "Dag moet een nummer zijn tussen 1 en 31.";
    dayValid.value = false;
  } else {
    dayError.value = "";
    dayValid.value = true;
  }
}

const validateStartTime = () => {
  const timeRegex = /^(?:2[0-3]|[01]?[0-9]):[0-5]?[0-9]$/; // Regular expression for validating time in HH:mm format
  if (!timeRegex.test(workingTime.startTime)) {
    startTimeError.value = "Ongeldige starttijd. Gebruik het formaat HH:mm.";
    startTimeValid.value = false;
  } else {
    startTimeError.value = "";
    startTimeValid.value = true;
  }
}

const validateEndTime = () => {
  const timeRegex = /^(?:2[0-3]|[01]?[0-9]):[0-5]?[0-9]$/; // Regular expression for validating time in HH:mm format
  if (workingTime.endTime.toLowerCase() !== 'sluit' && !timeRegex.test(workingTime.endTime)) {
    endTimeError.value = "Ongeldige eindtijd. Gebruik het formaat HH:mm of 'sluit'.";
    endTimeValid.value = false;
  } else {
    endTimeError.value = "";
    endTimeValid.value = true;
  }
}



const validateTip = () => {
  const tipPattern = /^[0-9]+([,.][0-9]{2})?$/;
  if (workingTime.tip === '') {
    tipError.value = ""; // Resetting error message if tip is empty
    tipValid.value = true; // Empty tip is considered valid
  } else if (tipPattern.test(workingTime.tip)) {
    tipError.value = "";
    tipValid.value = true;
  } else {
    tipError.value = "Ongeldige fooi. Gebruik precies twee cijfers na de komma indien aanwezig.";
    tipValid.value = false;
  }
}


const dayClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (dayValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const startTimeClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (startTimeValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const endTimeClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (endTimeValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const tipClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (tipValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const buttonClass = computed(() => {
  if (dayValid.value === false || startTimeValid.value === false || endTimeValid.value === false || tipValid.value === false || !workingTime.day || !workingTime.startTime || !workingTime.endTime) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});


onMounted(() => {
  getUserByToken()
})

</script>