<template>
  <div class="dark:bg-stone-800 flex md:p-7 p-2 justify-center">
    <div class="w-11/12 md:w-8/12 flex flex-col gap-4">
      <div class="flex md:gap-8 lg:gap-4 justify-between items-center w-full">
        <YearSelector class="w-3/12"></YearSelector>
        <MonthSelector class="w-7/12 md:w-6/12 lg:w-4/12"></MonthSelector>
      </div>
      <h1 class="dark:text-stone-100 text-2xl font-bold">Werktijden</h1>

      <!-- Add Work Expenses Button -->
      <div class="flex justify-start mb-4">
        <button
            @click.prevent="createWorkExpense"
            class="dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button"
        >
          Voeg werkuitgaven toe
        </button>
      </div>
      <div>
        <p class="dark:text-stone-100"><span class="italic">Italic</span>: Werktijd ligt in de toekomst</p>
        <p class="dark:text-stone-100"><span class="font-bold">Bold</span>: Werktijd is vandaag!</p>
        <p class="dark:text-stone-100"><span class="underline">Underline</span>: Werktijd is een feestdag</p>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>



<script setup>
import YearSelector from "@/components/workingtimes/components/YearSelector.vue";
import MonthSelector from "@/components/workingtimes/components/MonthSelector.vue";
import {inject, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import useEventsBus from "../../../eventBus";
const user = reactive({
  id: '',
  birthDate: '',
  age: '',
  permanentContract: '',
  hourlyWage: ''
});
const router = useRouter();
const route = useRoute()
const selectedMonth = ref(route.params.monthId)
const selectedYear = ref(route.params.yearId)
const { workExpenseService, sessionSBService, userService } = inject('data');
const workExpenses = reactive({ list: [] });
const token = sessionSBService.getTokenFromBrowserStorage();

const { emit } = useEventsBus();

const createWorkExpense = async () => {
  await router.push(`/werkuitgaven/aanmaken/${selectedYear.value}/${selectedMonth.value}`);
};

const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });
    await getAllWorkExpenses()
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')

      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }
};

const getAllWorkExpenses = async () => {
  try {
    const { data } = await workExpenseService.getAllWorkExpensesBy(selectedMonth.value, user.id);
    workExpenses.list = data.map(e => ({
      ...e,
      priceFormatted: e.price.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
    }));
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {
  getUserByToken()
})
</script>
