<template>
  <div class="dark:bg-stone-800 p-7 flex justify-center">
    <div class="w-8/12">
      <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
      <form class="flex flex-col my-4 gap-2">
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': workExpense.day }" for="day">Dag</label>
          <input v-model="workExpense.day" @input="validateDay" :class="dayClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="day"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ dayError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': workExpense.item }" for="item">Item</label>
          <input v-model="workExpense.item" @input="validateItem" :class="itemClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="item"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ itemError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': workExpense.location }" for="location">Locatie</label>
          <input v-model="workExpense.location" @input="validateLocation" :class="locationClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="location"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ locationError }}</p>
        </div>
        <div class="flex flex-col relative input-container">
          <label class="p-2 label dark:text-stone-100" :class="{ 'active': workExpense.price }" for="price">Prijs</label>
          <input v-model="workExpense.price" @input="validatePrice" :class="priceClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="price"/>
          <p class="p-1 error-min-height text-red-500 text-xs italic">{{ priceError }}</p>
        </div>
        <div class=" w-full ">
          <button v-if="!isLoading" class="w-full" type="submit" :disabled="dayValid === false || itemValid === false || priceValid === false || locationValid === false || !workExpense.day || !workExpense.item || !workExpense.price || !workExpense.location" @click.prevent="createWorkExpense" :class="buttonClass">Aanmaken</button>
          <Spinner v-if="isLoading"></Spinner>

        </div>
      </form>
    </div>
  </div>

</template>

<script setup>
import {computed, inject, onMounted, reactive, ref} from "vue";
import router from "@/router";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import useEventsBus from "../../../eventBus";
import Spinner from "@/components/Spinner.vue";
const store = useStore();
const isLoading = ref(false)
const workExpense = reactive({
  id:'',
  day: '',
  item: '',
  price: '',
  location: '',
  month: {
  }
})
const user = reactive({
  id: ''
});


const route = useRoute()
const {workExpenseService, userService, sessionSBService} = inject('data')
const dayValid = ref(null)
const itemValid = ref(null)
const locationValid = ref(null)
const priceValid = ref(null)
const dayError = ref('')
const itemError = ref('')
const locationError = ref('')
const priceError = ref('')
const createSuccessful = ref(null)
const { emit } = useEventsBus();
const token = sessionSBService.getTokenFromBrowserStorage();
const getUserByToken = async () => {
  try {
    const { data } = await userService.getUserByToken(token);
    Object.assign(user, { ...data });

  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')
      await router.push({path: '/inloggen'})
    }
    console.error(error);
  }
};

const back = () => {
  router.push(`/werktijden/${route.params.yearId}/${route.params.monthId}`)
}





const createWorkExpense = async () => {
  try {
    isLoading.value = true
    workExpense.price = parseFloat(workExpense.price.replace(",","."))


    const {message} = await workExpenseService.createWorkExpense(workExpense, route.params.monthId, user.id)
    store.commit('setSuccessMessage', message)
    await router.push(`/werktijden/${route.params.yearId}/${route.params.monthId}`)

  } catch (e) {
    store.commit('setErrorMessage', e.message)

  } finally {
    isLoading.value = false
  }
}

const validateDay = () => {
  const day = parseInt(workExpense.day); // Assuming dayInput is the input element for the day
  if (createSuccessful.value === false) {
    createSuccessful.value = null
    dayValid.value = null
    itemValid.value = null
    priceValid.value = null
  } else if (isNaN(day) || day < 1 || day > 31) {
    dayError.value = "Dag moet een nummer zijn tussen 1 en 31.";
    dayValid.value = false;
  } else {
    dayError.value = "";
    dayValid.value = true;
  }
}

const validateItem = () => {
  const itemRegex = /^[a-zA-Z0-9\s]+$/; // Regular expression for validating words, numbers, and spaces
  if (!itemRegex.test(workExpense.item) || workExpense.item.trim() === "") {
    itemError.value = "Ongeldige item naam. Gebruik alleen letters, cijfers en spaties.";
    itemValid.value = false;
  } else {
    itemError.value = "";
    itemValid.value = true;
  }
};

const validatePrice = () => {
  const pricePattern = /^[0-9]+([,.][0-9]{2})?$/;
  if (workExpense.price === '') {
    priceError.value = ""; // Resetting error message if tip is empty
    priceValid.value = true; // Empty tip is considered valid
  } else if (pricePattern.test(workExpense.price)) {
    priceError.value = "";
    priceValid.value = true;
  } else {
    priceError.value = "Ongeldige prijs. Gebruik precies twee cijfers na de komma indien aanwezig.";
    priceValid.value = false;
  }
}

const validateLocation = () => {
  const locationRegex = /^[a-zA-Z0-9\s]+$/; // Regular expression for validating location names
  if (workExpense.location.trim() === "") {
    locationError.value = "Locatie mag niet leeg zijn."; // Error for empty input
    locationValid.value = false;
  } else if (locationRegex.test(workExpense.location)) {
    locationError.value = "";
    locationValid.value = true;
  } else {
    locationError.value = "Ongeldige locatie. Gebruik alleen letters, cijfers en spaties.";
    locationValid.value = false;
  }
};



const dayClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (dayValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const itemClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (itemValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const locationClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (locationValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const priceClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (priceValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})


const buttonClass = computed(() => {
  if (dayValid.value === false || itemValid.value === false || priceValid.value === false || !workExpense.day || !workExpense.item || !workExpense.price) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});


onMounted(() => {
  getUserByToken()
})

</script>