<template>
  <NavBar></NavBar>
  <router-view></router-view>
  <Notification></Notification>
  <Footer></Footer>
</template>

<script setup>
import NavBar from "@/components/navbar/NavBar.vue";
import { onUnmounted, provide, shallowReactive } from "vue";
import { SessionSbService } from "@/services/SessionSbService";
import CONFIG from "../app-config";
import { FetchInterceptor } from "@/services/FetchInterceptor";
import { UserService } from "@/services/UserService";
import Notification from "@/components/Notification.vue";
import { RoleService } from "@/services/RoleService";
import { YearService } from "@/services/YearService";
import { MonthService } from "@/services/MonthService";
import Footer from "@/components/Footer.vue";
import { WorkingTimeService } from "@/services/WorkingTimeService";
import { InternshipTimesService } from "@/services/InternshipTimesService";
import { InternshipsService } from "@/services/InternshipsService";
import { WorkExpenseService } from "@/services/workExpenseService";

const theSessionSbService = shallowReactive(new SessionSbService(CONFIG.BACKEND_URL + '/auth', CONFIG.JWT_STORAGE_ITEM))
const theFetchInterceptor = new FetchInterceptor(theSessionSbService);

provide('data', {
  sessionSBService : shallowReactive(new SessionSbService(CONFIG.BACKEND_URL + '/auth', CONFIG.JWT_STORAGE_ITEM)),
  userService: new UserService(CONFIG.BACKEND_URL + '/users'),
  roleService: new RoleService(CONFIG.BACKEND_URL + '/roles'),
  yearService: new YearService(CONFIG.BACKEND_URL + '/years'),
  monthService: new MonthService(CONFIG.BACKEND_URL + '/months'),
  workingTimeService: new WorkingTimeService(CONFIG.BACKEND_URL + '/workingtimes'),
  workExpenseService: new WorkExpenseService(CONFIG.BACKEND_URL + '/workexpenses'),
  internshipTimeService: new InternshipTimesService(CONFIG.BACKEND_URL + '/internshiptimes'),
  internshipService: new InternshipsService(CONFIG.BACKEND_URL + '/internships'),
})

onUnmounted(() => {
  theFetchInterceptor.unregister()
})
</script>
