<template>
  <div>
    <form class="flex flex-col my-4 gap-2">
      <div v-for="setting in copySettings.settings" :key="setting.id"  class="flex flex-col relative input-container">
        <label class="p-2 text-xs opacity-50 dark:text-stone-100"
               :class="{ 'active-label': setting.turnedOn }"
               :for="`setting_${setting.id}`">{{ setting.frontendName }}</label>
        <div class="relative ">
          <input type="checkbox"
                 v-model="setting.turnedOn"
                 class="hidden"
                 :id="`setting_${setting.id}`" />
          <div class="mx-2 select-none toggle-bg  h-6 w-12 rounded-full shadow-inner"  @click="toggleSetting(setting)"
               :class="setting.turnedOn ? 'bg-orange-500 dark:bg-orange-700' : 'bg-gray-200 dark:bg-stone-700'" ></div>
          <div class="mx-2 select-none cursor-pointer toggle-dot absolute top-1 left-1 bg-white dark:bg-stone-100 w-4 h-4 rounded-full transition transform"
               :class="{ 'translate-x-6': setting.turnedOn }" @click="toggleSetting(setting)"></div>
        </div>
      </div>
      <button v-if="!isLoading" type="submit" :disabled="isSettingEdited === false" :class="buttonClass" @click.prevent="updateSettings" >Opslaan</button>
      <Spinner v-if="isLoading"></Spinner>

    </form>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, reactive, ref} from "vue";
import router from "@/router";
import useEventsBus from "../../../../eventBus";
import {useStore} from "vuex";
import Spinner from "@/components/Spinner.vue";
const store = useStore();
const isLoading = ref(false)
const {sessionSBService, userService} = inject('data');
const {emit} = useEventsBus();

const settings = reactive({
  settings: []
});

const copySettings = reactive({
  settings: []
});

const user = reactive({
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: ''
});


const toggleSetting = (setting) => {
  setting.turnedOn = !setting.turnedOn
}
const token = sessionSBService.getTokenFromBrowserStorage();

const getUserByToken = async () => {
  try {
    const {data} = await userService.getUserByToken(token);
    Object.assign(user, {...data});
    await getSettings();
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout');
      await router.push({path: '/inloggen'});
    }
    console.error(error);
  }
};

const isSettingEdited = computed(() => {
  // Check if any setting in settings has been edited compared to copySettings
  return settings.settings.some((setting, index) => {


    return setting.turnedOn !== copySettings.settings[index].turnedOn;
  });
});

const buttonClass = computed(() => {
  if (isSettingEdited.value === false) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});

const getSettings = async () => {
  try {
    const {data} = await userService.getUserSettingsById(user.id);
    // Assuming data is an array of settings like [{ id, frontendName, turnedOn }, ...]
    settings.settings = data.map(setting => ({
      id: setting.id,
      frontendName: setting.frontendName,
      turnedOn: setting.turnedOn
    }));

    updateCopySettings()
  } catch (e) {
    console.error(e);
  }
};

const updateSettings = async () => {
  try {
    isLoading.value = true
    const {message, data} = await userService.updateUserSettings({settings: copySettings.settings}, user.id)
    settings.settings = data.settings.map(setting => ({
      id: setting.id,
      frontendName: setting.frontendName,
      turnedOn: setting.turnedOn
    }));
    store.commit('setSuccessMessage', message)

  } catch (e) {

    store.commit('setErrorMessage', e.message)
    console.error(e);
  } finally {
    isLoading.value = false
  }
}

const updateCopySettings = () => {
  copySettings.settings = JSON.parse(JSON.stringify(settings.settings));
};
onMounted(() => {
  getUserByToken();
});

</script>
