import CustomError from "../../CustomError";

export class WorkExpenseService {
    url
    constructor(url) {
        this.url = url;
    }

    async fetchJson(url, options) {
        try {
            const response = await fetch(url, options);

            if (response.ok) {

                return await response.json();
            }
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getAllWorkExpensesBy(monthId, userId) {
        try {
            return await this.fetchJson(`${this.url}/${monthId}/${userId}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }


    async getWorkExpenseById(id) {
        try {
            return await this.fetchJson(`${this.url}/${id}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async createWorkExpense(workExpense, monthId, userId) {
        try {
            return await this.fetchJson(`${this.url}/${monthId}/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(workExpense),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async updateWorkExpenses(workExpense) {
        try {
            return await this.fetchJson(`${this.url}/${workExpense.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(workExpense),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async removeWorkExpense(workExpenseId) {
        try {
            return await this.fetchJson(`${this.url}/${workExpenseId}`, {
                method: 'DELETE'
            })
        } catch (e) {
            const customError = new CustomError(e.message, e.timestamp, e.details, e.status)
            throw customError.toJSON()
        }
    }

}