import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import './assets/main.css'
import '@vueform/multiselect/themes/default.css'
import '@fortawesome/fontawesome-free/css/all.css' // Import FontAwesome CSS
import router from './router'
import store from './store'

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
