<template>
  <div>
    <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
    <form class="flex flex-col my-4 gap-2">
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyMonth.month }" for="email">Maand</label>
        <input v-model="copyMonth.month" @input="validateMonth" :class="monthClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="email"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ monthError }}</p>
      </div>
      <div>
        <label for="data"  class="dark:bg-stone-800 dark:text-stone-100 ">
          <select v-model="selectedYear" @change="updateCopyYear"  class="w-32 select-none focus:outline-none dark:bg-stone-800 dark:text-stone-100 input-field focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent hover:border-b-orange-600  border-t hover:border-t-orange-600 border-l hover:border-l-orange-600  border-r hover:border-r-orange-600" name="year" id="year">
            <option disabled value="0">Selecteer een jaar</option>
            <option v-for="year in years.list" :key="year.id" :value="year.id">{{ year.year }}</option>
          </select>
        </label>
      </div>
      <div class=" w-full ">
        <button v-if="!isLoading" class="w-full" type="submit" :disabled="isEdited === false || monthValid === false || !copyMonth.month" @click.prevent="editMonth" :class="buttonClass">Wijzigen</button>
        <Spinner v-if="isLoading"></Spinner>

      </div>
    </form>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import {useRoute} from "vue-router";
import Spinner from "@/components/Spinner.vue";
const selectedYear = ref(null);
const store = useStore();
const monthId = ref('')
const route = useRoute();
const month = reactive({
  id:'',
  month: '',
  year: {

  }
})
const isLoading = ref(false)
const year = reactive({
  id:'',
  year: ''
})
const copyMonth = reactive({
  id:'',
  month: '',
  year: {

  }
})
const copyYear = reactive({
  id:'',
  year: ''
})
const years = reactive({list: []})

const {yearService, monthService} = inject('data')
const monthValid = ref(null)
const monthError = ref('')
const createSuccessful = ref(null)



const back = () => {
  router.push("/profiel/admin-panel/maanden")
}
const updateCopyYear = () => {
  const selectedYearId = selectedYear.value;
  const selectedYearData = years.list.find(year => year.id === selectedYearId);
  if (selectedYearData) {
    copyYear.id = selectedYearData.id;
    copyYear.year = selectedYearData.year;
  }
}
const getAllYears = async () => {
  try {
    const {data} = await yearService.getAllYears()
    years.list = data.map(u => ({...u}));
  } catch (error) {
    console.error(error)
  }
}



const getMonthById = async () => {
  try {
    const {data} = await monthService.getMonthById(monthId.value);
    // Filter out the logged-in user from the list of all users
    Object.assign(month, { ...data });
    Object.assign(year, {...data.year})
    Object.assign(copyMonth, {...month})
    Object.assign(copyYear, {...month.year})
    selectedYear.value = copyYear.id
  } catch (e) {
    console.error(e);
  }
};

const editMonth = async () => {
  try {
    isLoading.value = true
    const savedMonth = {
      id: copyMonth.id,
      month: copyMonth.month,
      year: {
        id: copyYear.id,
        year: copyYear.year
      }
    }
    const { message } =  await monthService.updateMonth(savedMonth)
    store.commit('setSuccessMessage', message)
    await router.push({path: '/profiel/admin-panel/maanden'})
    createSuccessful.value = true

  } catch (e) {
    createSuccessful.value = false
    monthValid.value = false
    store.commit('setErrorMessage', e.message)
  } finally {
    isLoading.value = false
  }
}



const validateMonth = () => {
  const validDutchMonths = [
    "Januari", "Februari", "Maart", "April", "Mei", "Juni",
    "Juli", "Augustus", "September", "Oktober", "November", "December"
  ];

  if (copyMonth.month === "") {
    monthError.value = "Maand mag niet leeg zijn.";
    monthValid.value = false;
  } else if (validDutchMonths.includes(copyMonth.month)) {
    monthError.value = "";
    monthValid.value = true;
  } else {
    monthError.value = "Maand moet een geldige Nederlandse maand zijn met een hoofdletter.";
    monthValid.value = false;
  }
}


const isEdited = computed(() => {
  const isMonthEdited = (month.month !== copyMonth.month)
  const isYearEdited = (year.year !== copyYear.year)

  return (isMonthEdited || isYearEdited)
});


const monthClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (monthValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})



const buttonClass = computed(() => {
  if (monthValid.value === false || !copyMonth.month || !isEdited.value) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});

onMounted(() => {
  monthId.value = route.params.id
  getAllYears()
  getMonthById()
})

</script>