<template>
  <div class="w-full flex items-center justify-center watermark">
    <div class="spinner"></div>
  </div>
</template>

<style scoped>
.spinner {
  border: 4px solid transparent;
  border-top: 4px solid #f97316; /* Orange color */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<script setup lang="ts">
</script>
