<template>
  <div>
    <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
    <form class="flex flex-col my-4 gap-2">
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.email }" for="email">Email</label>
        <input v-model="copyUser.email"  @input="validateEmail" :class="emailClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="email"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{emailError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.firstname }" for="firstname">Voornaam</label>
        <input v-model="copyUser.firstname"  @input="validateFirstname" :class="firstnameClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="firstname"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ firstnameError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.lastname }" for="lastname">Achternaam</label>
        <input v-model="copyUser.lastname" @input="validateLastname" :class="lastnameClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="lastname"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ lastnameError}}</p>
      </div>
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.phoneNumber }" for="phoneNumber">Telefoonnummer</label>
        <input v-model="copyUser.phoneNumber" @input="validatePhoneNumber" :class="phoneNumberClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="phoneNumber"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ phoneNumberError}}</p>
      </div>
      <div v-if="hasRoleId(2)" class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyUser.hourlyWage }" for="hourlyWage">Uurloon</label>
        <input v-model="copyUser.hourlyWage" @input="validateHourlyWage" :class="hourlyWageClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="hourlyWage"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{ hourlyWageError}}</p>
      </div>
      <div v-if="hasRoleId(4)" class="flex flex-col relative input-container">
        <!-- Label for Child Select -->
        <label
            class="z-10 absolute top-1 left-4 text-xs opacity-60 text-stone-600 dark:text-stone-100 transition-all pointer-events-none"
            for="childSelect"
        >
          Kind
        </label>
        <!-- Child Select Field -->
        <select :class="childClass"
                v-model="copyUser.childId"
                class="w-full dark:bg-stone-800 dark:text-stone-100 border-transparent input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-10 pl-4 rounded border-b-4 focus:border-b-orange-600 border-t border-l border-r appearance-none relative"
                id="childSelect"
        >
          <option disabled value="0">Selecteer een kind</option>
          <option v-for="user in users" :key="user.id" :value="`${user.id}`">
            {{ user.firstname }} {{ user.lastname }}
          </option>
        </select>

        <!-- Custom Arrow for Child Select -->
        <span class="pointer-events-none absolute right-4 opacity-60 top-6 text-stone-600 dark:text-stone-100">
    ▼
  </span>
      </div>
      <div class="flex w-full justify-between items-center">
        <button v-if="!isLoading"
            class="w-7/12"
            type="submit"
            :disabled="
          isEdited === false ||
  emailValid === false ||
  firstnameValid === false ||
  lastnameValid === false ||
  phoneNumberValid === false ||
  !copyUser.firstname ||
  !copyUser.email ||
  !copyUser.lastname ||
  !copyUser.phoneNumber ||
  copyUser.roles.length === 0 ||
  (hasRoleId(2) && (!copyUser.hourlyWage || !hourlyWageValid)) ||
    (hasRoleId(4) && !copyUser.childId)"
            @click.prevent="editUser"
            :class="buttonClass"
        >
          Wijzigen
        </button>
        <Spinner v-if="isLoading"></Spinner>

        <div class="flex flex-col relative input-container w-full md:w-72">
          <!-- Label for Role Select -->
          <label
              class="z-10 absolute top-1 left-4 text-xs opacity-60 text-stone-600 dark:text-stone-100 transition-all pointer-events-none"
              for="roleSelect"
          >
            Rollen
          </label>

          <!-- Custom Select Display -->
          <div
              @click="toggleDropdown"
              class="w-full md:w-72 dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-10 pl-4 rounded border-b-4 border-b-stone-200 dark:border-b-stone-600 hover:border-b-orange-600 focus:border-b-orange-600 border-t-0 border-l-0 border-r-0 relative cursor-pointer"
              ref="roleSelectBox"
          >
            <div v-if="copyUser.roles.length === 0" class="text-stone-600 dark:text-stone-100 opacity-60">Selecteer rollen</div>
            <div v-else class="flex flex-col space-y-1">
      <span
          v-for="role in copyUser.roles"
          :key="role.id"
          class="text-sm"
      >
        {{ getRoleName(role.id) }}
      </span>
            </div>
            <span class="pointer-events-none absolute right-4 opacity-60 top-6 text-stone-600 dark:text-stone-100">
      ▼
    </span>
          </div>

          <!-- Dropdown Menu -->
          <div
              v-show="dropdownVisible"
              class="absolute z-10 w-full md:w-72 dark:bg-stone-800 bg-white shadow-lg rounded mt-1 border border-stone-600 dark:border-stone-600"
              @click.stop
          >
            <div
                v-for="role in roles"
                :key="role.id"
                class="px-4 py-2 flex items-center cursor-pointer hover:bg-orange-600 dark:hover:bg-orange-500"
                @click="toggleRoleSelection(role)"
            >
      <span class="dark:text-stone-100 opacity-60" :class="{'font-bold': hasRoleId(role.id)}">
        {{ role.frontendName }}
      </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import {computed, inject, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import Spinner from "@/components/Spinner.vue";
const store = useStore();
const user = reactive({
  id:'',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
  password: '',
  childId: '',
  roles: []
})
const isLoading = ref(false)
const copyUser = reactive({
  id:'',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
  password: '',
  childId: '',
  roles: []
})
const route = useRoute();
const router = useRouter();
const loggedInUser = reactive({
  id:'',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
  password: '',
  childId: '',
  roles: []
});
const users = reactive ([])
const roles = reactive([])
const {userService, sessionSBService, roleService} = inject('data')
const emailValid = ref(null)
const firstnameValid = ref(null)
const lastnameValid = ref(null)
const phoneNumberValid = ref(null)
const hourlyWageValid = ref(null)
const childValid = ref(null)
const emailError = ref('')
const firstnameError = ref('')
const lastnameError = ref('')
const phoneNumberError = ref('')
const hourlyWageError = ref('')
const createSuccessful = ref(null)
const userId = ref('')
const back = () => {
  router.push("/profiel/admin-panel/gebruikers")
}

const token = sessionSBService.getTokenFromBrowserStorage()

const getUserById = async () => {
  try {
    const {data} = await userService.getUserById(userId.value)

    Object.assign(user, { ...data });
    user.hourlyWage = user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
    copyUser.id = user.id
    copyUser.email = user.email;
    copyUser.firstname = user.firstname;
    copyUser.lastname = user.lastname;
    copyUser.phoneNumber = user.phoneNumber;
    copyUser.hourlyWage = user.hourlyWage;
    copyUser.childId = user.childId;
    copyUser.roles = JSON.parse(JSON.stringify(user.roles)); // Deep copy
    await getRoles()

  } catch (error) {
    console.error(error)
  }
}

const getRoles = async () => {
  try {
    const { data } = await roleService.getAllRoles()
    for (let i = 0; i < data.length; i++) {
      roles.push(data[i])
    }
    await getAllUsers()
  } catch (e) {
    console.error(e)
  }
}

const editUser = async () => {
  try {
    isLoading.value = true
    if (hasRoleId(2)) {
      copyUser.hourlyWage = parseFloat(copyUser.hourlyWage.replace(",","."))
    } else if (!hasRoleId(2)){
      copyUser.hourlyWage = ''
    } else if (!hasRoleId(4)) {
      copyUser.childId = ''

    }
    copyUser.email = copyUser.email.toLowerCase()
    console.log(copyUser)
    const { message } =  await userService.updateUser(copyUser)
    store.commit('setSuccessMessage', message)
    await router.push({path: '/profiel/admin-panel/gebruikers'})
    createSuccessful.value = true
  } catch (e) {
    createSuccessful.value = false
    emailValid.value = false
    firstnameValid.value = false
    lastnameValid.value = false
    phoneNumberValid.value = false
    hourlyWageValid.value = false
    childValid.value = false
    store.commit('setErrorMessage', e.message)

  } finally {
    isLoading.value = false
  }
}
const isEdited = computed(() => {
  const isEmailEdited = (user.email !== copyUser.email)
  const isFirstnameEdited = (user.firstname !== copyUser.firstname)
  const isLastnameEdited = (user.lastname !== copyUser.lastname)
  const isPhoneNumberEdited = (user.phoneNumber !== copyUser.phoneNumber)
  const isHourlyWageEdited = (user.hourlyWage !== copyUser.hourlyWage)
  const isChildEdited = (user.childId !== copyUser.childId)


  return (isEmailEdited || isFirstnameEdited || isLastnameEdited || isPhoneNumberEdited || isHourlyWageEdited || isChildEdited || isRolesEdited.value)

});

const isRolesEdited = computed(() => {
  // Check if lengths differ
  console.log(user.roles)
  console.log(copyUser.roles)
  if (user.roles.length !== copyUser.roles.length) {
    return true;
  }

  // Check if the arrays have different IDs
  const userRoleIds = user.roles.map(role => role.id).sort();
  const copyUserRoleIds = copyUser.roles.map(role => role.id).sort();

  return !userRoleIds.every((id, index) => id === copyUserRoleIds[index]);
});



const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (createSuccessful.value === false) {
    createSuccessful.value = null;
    emailValid.value = null
    firstnameValid.value = null
    lastnameValid.value = null
    hourlyWageValid.value = null
    phoneNumberValid.value = null
    childValid.value = null
  } else if (copyUser.email === "") {
    emailError.value = "Email mag niet leeg zijn.";
    emailValid.value = false;
  } else if (emailPattern.test(copyUser.email)) {
    emailError.value = "";
    emailValid.value = true;
  } else {
    emailError.value = "Ongeldig e-mailadres.";
    emailValid.value = false;
  }
}

const validateFirstname = () => {
  const firstnamePattern = /^[A-Z][a-zA-Z]*$/;
  if (createSuccessful.value === false) {
    createSuccessful.value = null;
    emailValid.value = null
    firstnameValid.value = null
    lastnameValid.value = null
    hourlyWageValid.value = null
    phoneNumberValid.value = null
    childValid.value = null
  } else if (copyUser.firstname === "") {
    firstnameError.value = "Voornaam mag niet leeg zijn.";
    firstnameValid.value = false;
  } else if (firstnamePattern.test(copyUser.firstname)) {
    firstnameError.value = "";
    firstnameValid.value = true;
  } else if (!/^[A-Z]/.test(copyUser.firstname)) {
    firstnameError.value = "Voornaam moet beginnen met een hoofdletter.";
    firstnameValid.value = false;
  } else {
    firstnameError.value = "Voornaam mag alleen alfabetische tekens bevatten.";
    firstnameValid.value = false;
  }
}

const validateLastname = () => {
  const lastnamePattern = /\b[A-Z][a-zA-Z]*\b/;
  if (createSuccessful.value === false) {
    createSuccessful.value = null;
    emailValid.value = null
    firstnameValid.value = null
    lastnameValid.value = null
    hourlyWageValid.value = null
    phoneNumberValid.value = null
    childValid.value = null
  } else if (copyUser.lastname === "") {
    lastnameError.value = "Achternaam mag niet leeg zijn.";
    lastnameValid.value = false;
  } else if (lastnamePattern.test(copyUser.lastname)) {
    lastnameError.value = "";
    lastnameValid.value = true;
  } else {
    lastnameError.value = "Achternaam moet minstens één woord met een hoofdletter bevatten.";
    lastnameValid.value = false;
  }
}

const validatePhoneNumber = () => {
  const localPhoneNumberPattern = /^0[1-9][0-9]{8}$/;
  const internationalPhoneNumberPattern = /^(?:\+31|0031)[1-9][0-9]{8}$/;
  if (createSuccessful.value === false) {
    createSuccessful.value = null;
    emailValid.value = null
    firstnameValid.value = null
    lastnameValid.value = null
    hourlyWageValid.value = null
    phoneNumberValid.value = null
    childValid.value = null
  } else if (copyUser.phoneNumber === "") {
    phoneNumberError.value = "Telefoonnummer mag niet leeg zijn.";
    phoneNumberValid.value = false;
  } else if (localPhoneNumberPattern.test(copyUser.phoneNumber) || internationalPhoneNumberPattern.test(copyUser.phoneNumber)) {
    phoneNumberError.value = "";
    phoneNumberValid.value = true;
  } else {
    phoneNumberError.value = "Ongeldig Nederlands telefoonnummer.";
    phoneNumberValid.value = false;
  }
}

const validateHourlyWage = () => {
  if (!hasRoleId(2)) {
    hourlyWageValid.value = true;
    return true;
  }

  const hourlyWagePattern = /^[0-9]+([,.][0-9]{2})?$/;
  if (createSuccessful.value === false) {
    createSuccessful.value = null;
    emailValid.value = null
    firstnameValid.value = null
    lastnameValid.value = null
    hourlyWageValid.value = null
    phoneNumberValid.value = null
    childValid.value = null
  } else if (user.hourlyWage === "") {
    hourlyWageError.value = "Uurloon mag niet leeg zijn.";
    hourlyWageValid.value = false;
  } else if (hourlyWagePattern.test(user.hourlyWage)) {
    hourlyWageError.value = "";
    hourlyWageValid.value = true;
  } else {
    hourlyWageError.value = "Ongeldig uurloon. Gebruik precies twee cijfers na de komma indien aanwezig.";
    hourlyWageValid.value = false;
  }

  return hourlyWageValid.value;
};


const emailClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (emailValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const firstnameClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (firstnameValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const lastnameClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (lastnameValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const phoneNumberClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (phoneNumberValid.value === false ) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const hourlyWageClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (hourlyWageValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})

const childClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (childValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600 ";
  }
})

const getUserByToken = async () => {
  try {
    const {data} = await userService.getUserByToken(token)
    Object.assign(loggedInUser, { ...data });
    loggedInUser.hourlyWage = loggedInUser.hourlyWage.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    await getUserById()
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      await router.push({path: '/inloggen'})
    }
    console.error(error)
  }
}

const getAllUsers = async () => {
  try {
    const { data } = await userService.getAllUsers();
    for (let i = 0; i < data.length; i++) {
      users.push(data[i])
    }
  } catch (e) {
    console.error(e);
  }
};


const buttonClass = computed(() => {
  const isDisabled =
      isEdited.value === false ||
      emailValid.value === false ||
      firstnameValid.value === false ||
      lastnameValid.value === false ||
      phoneNumberValid.value === false ||
      !copyUser.firstname ||
      !copyUser.email ||
      !copyUser.lastname ||
      !copyUser.phoneNumber ||
       copyUser.roles.length === 0 ||
      (hasRoleId(2) && (!copyUser.hourlyWage || !hourlyWageValid.value)) ||
      (hasRoleId(4) && !copyUser.childId)
  return isDisabled
      ? "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed"
      : "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
});

const hasRoleId = (roleId) => copyUser.roles.some(role => role.id === roleId);


const dropdownVisible = ref(false);
const roleSelectBox = ref(null);

function toggleDropdown() {
  dropdownVisible.value = !dropdownVisible.value;
}

function closeDropdown() {
  dropdownVisible.value = false;
}


watch(
    () => copyUser.roles,
    () => {
      if (hasRoleId(2)) {
        hourlyWageValid.value = copyUser.hourlyWage !== '' && validateHourlyWage(); // Initial validation when role is selected
      } else {
        hourlyWageValid.value = true; // Set to true or bypass if role 2 is not selected
      }
    }
);

// Helper method to get the name of the selected role
function getRoleName(roleId) {
  const role = roles.find((role) => role.id === roleId);
  return role ? role.frontendName : "";
}

function toggleRoleSelection(role) {
  if (hasRoleId(role.id)) {
    // If the role is already selected, remove it
    copyUser.roles = copyUser.roles.filter((r) => r.id !== role.id);

    // Set hourlyWage or childId to null if corresponding role is unselected
    if (role.id === 2) {
      copyUser.hourlyWage = '';
      hourlyWageValid.value = true; // Set valid state to true as it’s no longer required
    } else if (role.id === 4) {
      copyUser.childId =  '';
      childValid.value = true; // Set valid state to true as it’s no longer required
    }
  } else {
    // If the role is not selected, add it
    copyUser.roles.push(role);
  }
}

// Detect clicks outside the dropdown to close it
function handleClickOutside(event) {
  if (roleSelectBox.value && !roleSelectBox.value.contains(event.target)) {
    closeDropdown();
  }
}

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

onMounted(() => {
  userId.value = route.params.id
  document.addEventListener("click", handleClickOutside);

  getUserByToken()
})
</script>