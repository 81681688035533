<template>
  <!-- Success Message -->
  <transition name="fade-slide">
    <div
        v-if="successMessage"
        class="notification fixed top-28 sm:top-32 right-4 sm:right-8 z-10 bg-green-600 dark:bg-green-900 text-white shadow-lg rounded-xl p-4 flex items-center max-w-xs sm:max-w-sm"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
      </svg>
      <span class="flex-1 break-words">{{ successMessage }}</span>
      <span @click="removeSuccessMessage" class="ml-4 cursor-pointer hover:opacity-75 transition-opacity">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </span>
    </div>
  </transition>

  <!-- Error Message -->
  <transition name="fade-slide">
    <div
        v-if="errorMessage"
        class="notification fixed top-28 sm:top-32 right-4 sm:right-8 z-10 bg-red-600 dark:bg-red-900 text-white shadow-lg rounded-xl p-4 flex items-center max-w-xs sm:max-w-sm"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
      >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 8v4m0 4h.01M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2z"
        />
      </svg>
      <span class="flex-1 break-words">{{ errorMessage }}</span>
      <span @click="removeErrorMessage" class="ml-4 cursor-pointer hover:opacity-75 transition-opacity">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </span>
    </div>
  </transition>
</template>

<script setup>
import {useStore} from "vuex";
import {computed, watch} from "vue";
const store = useStore();
const successMessage = computed(() => store.state.successMessage);
const errorMessage = computed(() => store.state.errorMessage);

const removeSuccessMessage = () => {
  store.commit("setSuccessMessage", null);
};

const removeErrorMessage = () => {
  store.commit("setErrorMessage", null);
};

watch([successMessage, errorMessage], ([newSuccess, newError]) => {
  if (newSuccess) {
    setTimeout(() => removeSuccessMessage(), 6000);
  }
  if (newError) {
    setTimeout(() => removeErrorMessage(), 6000);
  }
});
</script>

<style>
/* Fade Slide Animation */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

/* Notification Styling */
.notification {
  transition: all 0.3s ease;
  max-width: 90%; /* Ensure notifications don't go beyond the viewport */
  word-wrap: break-word;
}

@media (min-width: 640px) {
  .notification {
    max-width: 24rem; /* Adjust for larger screens */
  }
}
</style>
