<template>
  <div class="p-4">
    <div class="relative w-64 ">
      <img class="shadow-own object-cover aspect-square rounded-lg "  :src="src ? src : `https://www.rickveerman.nl/${copyUser?.profilePicture}`" alt=""/>
      <input  type="file" class="hidden" ref="fileRef" @change="fileChange" >
      <button v-if="!src" class="focus:outline-none absolute top-0 w-full h-full hover:bg-black hover:bg-opacity-25 transition duration-200" @click.prevent="browse">
        <img   class="m-auto " src="../../../assets/camera-white.png"  alt=""/>
      </button>
      <div v-if="src" class="flex focus:outline-none absolute top-0 w-full h-full hover:bg-black hover:bg-opacity-25 transition duration-200">
        <button @click.prevent="resetFileChange" class="focus:outline-none m-auto w-2/6 rounded-full h-2/6  hover:bg-white hover:bg-opacity-25 transition duration-200">
          <img class="m-auto h-10 w-10" src="../../../assets/close.png"  alt=""/>
        </button>
        <button @click.prevent="updateProfilePicture" class="focus:outline-none m-auto w-2/6 h-2/6 rounded-full  hover:bg-white hover:bg-opacity-25 transition duration-200">
          <img class="m-auto h-10 w-10" src="../../../assets/check.png"  alt=""/>
        </button>
        <Spinner v-if="isLoading"></Spinner>

      </div>
    </div>
    <p class="p-1 error-min-height text-red-500 text-xs italic">{{errorMessage}}</p>

  </div>
</template>

<script setup>
import {inject, onMounted, reactive, ref} from "vue";
import useEventsBus from "../../../../eventBus";
import {useStore} from "vuex";
import router from "@/router";
import Spinner from "@/components/Spinner.vue";

const {sessionSBService, userService} = inject('data')
const fileRef = ref(null)
const store = useStore();
const { emit } = useEventsBus();
const src = ref(null)
const errorMessage = ref(null)
const user = reactive( {
  id: '',
  profilePicture: '',
  profilePictureFile: ''
})
const copyUser = reactive( {
  id: '',
  profilePicture: '',
})
const isLoading = ref(false)
const token = sessionSBService.getTokenFromBrowserStorage()

const getUserByToken = async () => {
  try {
    const {data} = await userService.getUserByToken(token)
    Object.assign(user, { ...data });
    Object.assign(copyUser, {...user})
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      emit('handleLogout')

      await router.push({path: '/inloggen'})
    }
    console.error(error)
  }
}

const resetFileChange = () => {
  src.value = null;
  user.profilePictureFile = '';
  if (fileRef.value) {
    fileRef.value.value = '';  // Clear the input field
  }
};

const updateProfilePicture = async () => {
  try {
    isLoading.value = true
    const {data, message} = await userService.updateProfilePicture(user)
    Object.assign(user, {...data});
    Object.assign(copyUser, {...user})
    emit("profilePictureChange")
    store.commit('setSuccessMessage', `${message}`)
    src.value = null;
    user.profilePictureFile = '';
    if (fileRef.value) {
      fileRef.value.value = '';  // Clear the input field
    }
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  } finally {
    isLoading.value = false
  }
}

const fileChange = async (e) => {
  const file = e.target.files[0];
  if (!file) return;
  const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const maxSize = 5 * 1024 * 1024; // 5MB

  if (!validTypes.includes(file.type)) {
    errorMessage.value = 'Alleen afbeeldingsbestanden (jpg, png, gif) zijn toegestaan.';
    resetFileChange();
    return;
  }

  if (file.size > maxSize) {
    errorMessage.value = 'Bestandsgrootte mag niet groter zijn dan 5MB.';
    resetFileChange();
    return;
  }

  const formData = new FormData();
  formData.append('file', file);
  user.profilePictureFile = formData;
  src.value = URL.createObjectURL(file);
  errorMessage.value = '';
}

const browse = () => {
  fileRef.value.click()
}

onMounted(() => {
  getUserByToken()
})
</script>