<template>
  <div class="w-full flex justify-center dark:bg-stone-800">
    <div class="my-10 p-7 flex shadow-lg md:w-2/4 w-3/4 flex-col">
      <h1 class="text-xl dark:text-stone-100">Voer Reset Code In</h1>
      <form class="flex flex-col my-4 gap-4" >
        <div class="flex flex-col items-center">
          <div class="flex gap-1 sm:gap-3 md:gap-5 justify-center items-center">
            <input
                v-for="(digit, index) in resetPasswordCode.code"
                :key="index"
                v-model="resetPasswordCode.code[index]"
                @paste="handlePaste"
                maxlength="1"
                class="dark:bg-stone-800 w-7/12 sm:w-3/12 md:w-5/12 lg:w-1/12 text-xs text-center md:text-2xl h-24 md:h-32 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600"
                type="text"
                :class="codeClass"
                @input="validateCode"
            />
          </div>
          <p class="text-red-500 text-xs italic mt-2" v-if="codeError">{{ codeError }}</p>
        </div>
        <button v-if="!isLoading" :class="buttonClass" @click.prevent="verifyResetCode" type="submit">Verifieer Reset Code</button>
        <Spinner v-if="isLoading"></Spinner>

      </form>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, inject, reactive} from 'vue';
import {useStore} from "vuex";
import useEventsBus from "../../../eventBus";
import {useRoute} from "vue-router";
import router from "@/router";
import Spinner from "@/components/Spinner.vue";
const store = useStore();
const { emit } = useEventsBus();
const {sessionSBService} = inject('data')
const route = useRoute()
const resetPasswordCode = reactive({
  email: route.params.email,
  code: Array(6).fill('')
})
const isLoading = ref(false)
const codeError = ref('');
const codeValid = ref(null)
const handlePaste = (event) => {
  const pasteData = event.clipboardData.getData('text');
  if (pasteData.length === 6 && !isNaN(pasteData)) {
    for (let i = 0; i < 6; i++) {
      resetPasswordCode.code[i] = pasteData[i];
    }
    codeValid.value = true
    codeError.value = '';
  } else {
    codeValid.value = false
    codeError.value = 'Reset code moet 6 cijfers lang zijn.';
  }
  event.preventDefault();
};

const verifyResetCode = async () => {
  try {
    isLoading.value = true
    const {message} = await sessionSBService.checkResetCode({code: resetPasswordCode.code.join(''), email: resetPasswordCode.email});
    store.commit('setSuccessMessage', message)
    emit('sendCodeEmail', {code: resetPasswordCode.code.join(''), email: resetPasswordCode.email})

    await router.push({path: '/wachtwoord-resetten'})
  } catch (e) {
    store.commit('setErrorMessage', e.message)

  } finally {
    isLoading.value = false
  }
};

const validateCode = () => {
  const joinedCode = resetPasswordCode.code.join('');
  if (codeValid.value === false) {
    codeValid.value = null
  } else if (joinedCode.length === 6 && !isNaN(joinedCode)) {
    codeValid.value = true;
    codeError.value = '';
  } else {
    codeValid.value = false;
    codeError.value = 'Reset code moet 6 cijfers lang zijn.';
  }
};



const codeClass = computed(() => {
  if (codeValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
});

const buttonClass = computed(() => {
  return resetPasswordCode.code.join('').length === 6
      ? 'dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button'
      : 'dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed';
});
</script>
